<template>
  <v-container class="container-full-size">
    <v-card color="basil" style="margin-top: 0" flat>
      <div v-if="showUserNotes" class="client-user-notes">
        <client-notes ref="clientNotes" :client="client" mode="nutrition" />
      </div>
      <div v-if="showWaterToConsume" class="client-user-notes" :class="{ 'pt-2': showUserNotes }">
        <v-btn color="secondary" class="mr-0" @click="openWaterToConsume()">
          {{ str['water_to_consume'] }}{{ waterToConsume ? ': ' + waterToConsume : '' }}
        </v-btn>
      </div>
      <div v-if="showLastFeedbackPhotos" class="client-user-photos">
        <client-photos :client="client" />
      </div>
      <div v-if="client.showInternalNotes" class="client-pt-notes">
        <div>
          <div>
            <v-textarea v-model="notesFoodPlan" :label="str['nutrition_plan_notes']" :rows="3" hide-details />
          </div>
        </div>
      </div>
      <v-col v-if="showFormulasListPage" cols="12" class="row-flex-align pb-0 warning--text">
        <div v-for="(goal, index) in getMacrosGoals()" :key="index">
          <span v-if="index > 0" style="margin: 0 10px">|</span>
          <b>{{ goal.label }}:</b>
          <span>
            {{ goal.value }}
          </span>
        </div>
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn
          v-if="showFormulasListPage && hasPermission('nutrition_edit')"
          color="blue"
          style="margin-right: 10px"
          @click="openFormulasDialog()"
        >
          {{ str['update_macros'] }}
        </v-btn>
        <v-btn
          v-if="client.showInternalNotes"
          color="secondary"
          :class="{
            'mr-4': hasPermission('nutrition_edit'),
            'mr-0': !hasPermission('nutrition_edit'),
          }"
          @click="client.saveInternalNotes()"
        >
          {{ str['save_notes'] }}
        </v-btn>
        <v-btn v-if="showAutoAddBasePlan && hasPermission('nutrition_edit')" color="purple" class="mr-4" @click="addAutoBasePlan()">
          {{ str['add_auto_base_plan'] }}
        </v-btn>
        <v-btn v-if="hasPermission('nutrition_edit')" color="success" class="mr-0" @click="addFoodPlan()">
          {{ str['add'] }}
        </v-btn>
      </v-col>
      <v-col v-if="showCaloriesFormulas && caloriesFormulas" cols="12">
        <h4>
          {{ str['calories'] }}
        </h4>
        <v-row>
          <v-col cols="2">
            <v-text-field
              v-model="caloriesFormulas.height"
              :label="str['height_cm']"
              required
              oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="caloriesFormulas.weight"
              :label="str['weight']"
              required
              oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="caloriesFormulas.age"
              :label="str['age']"
              required
              oninput="this.value = this.value.replace(/[^0-9]/g, '');"
              hide-details
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="caloriesFormulas.activityLevel"
              :label="str['physical_activity_level']"
              item-text="label"
              item-value="value"
              :items="caloriesFormulas.activityLevelItems"
              :no-data-text="str['no_data']"
              hide-details
            />
          </v-col>
          <v-col v-if="clientGoals && clientGoals.length" cols="3">
            <v-autocomplete
              v-model="clientGoal"
              :label="str['goal']"
              item-text="label"
              item-value="value"
              :items="clientGoals"
              :no-data-text="str['no_data']"
              hide-details
              @change="updateNotes('nutrition_goal', clientGoal)"
            />
          </v-col>
          <v-col cols="12" class="pt-0 pb-0">
            <div v-for="(formula, formulaIndex) in caloriesFormulas.getValues()" :key="formulaIndex" class="row-flex-align mb-2">
              <div class="mr-4">
                <b>{{ formula.title }}:</b>
                {{ formula.value }} kCal
              </div>
              <v-text-field
                v-model="caloriesFormulasInputs[formula.title]"
                required
                outlined
                :disabled="caloriesFormulasInputDisabled(formula.title)"
                placeholder="ex: -200, +200"
                hide-details
                class="input-medium"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card-text v-if="!foodPlans.length && isLoading" class="text-center" style="padding: 40px 0">
          {{ str['processing'] }}
        </v-card-text>
        <v-card-text v-if="!foodPlans.length && !isLoading" class="text-center" style="padding: 40px 0">
          {{ str['food_plans_empty'] }}
        </v-card-text>
        <v-list one-line style="min-height: 250px; padding: 0">
          <div v-for="foodPlan in foodPlans" :key="foodPlan.id">
            <v-list-item @click="openFoodPlan(foodPlan)">
              <v-list-item-content>
                <v-list-item-title>
                  {{ foodPlan.name }}
                  <span>
                    -
                    <span :class="client.getPlanStatus(foodPlan, 'food_plans', foodPlans).type + '--text'">
                      {{ client.getPlanStatus(foodPlan, 'food_plans', foodPlans).label }}
                    </span>
                  </span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div>
                    {{ foodPlan.date }}
                  </div>
                  <div v-if="showDateEnd && foodPlan.date_end" class="mt-1">
                    <b>{{ showDateEnd.text ? (str[showDateEnd.text] ? str[showDateEnd.text] : showDateEnd.text) : str['end_date'] }}:</b>
                    <span>
                      {{ foodPlan.date_end }}
                    </span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="list-item-actions">
                <v-btn
                  v-if="hasPermission('nutrition_edit') && foodPlanStatusDict.status[foodPlan.status] !== 'active'"
                  color="warning"
                  class="mr-0"
                  outlined
                  small
                  @click.stop="changeFoodPlanStatus(foodPlan, foodPlanStatusDict.values.active)"
                >
                  {{ str['activate'] }}
                </v-btn>
                <v-btn
                  v-if="hasPermission('nutrition_edit') && foodPlanStatusDict.status[foodPlan.status] !== 'finished'"
                  color="success"
                  class="mr-0"
                  outlined
                  small
                  @click.stop="changeFoodPlanStatus(foodPlan, foodPlanStatusDict.values.finished)"
                >
                  {{ str['finish'] }}
                </v-btn>
                <v-btn
                  v-if="hasPermission('nutrition_edit') && foodPlanStatusDict.status[foodPlan.status] !== 'cancelled'"
                  color="error"
                  class="mr-0"
                  outlined
                  small
                  @click.stop="changeFoodPlanStatus(foodPlan, foodPlanStatusDict.values.cancelled)"
                >
                  {{ str['cancel'] }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </v-list>
      </v-col>
    </v-card>

    <v-dialog v-model="dialogNewFoodPlan" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['nutrition_plans_base'] }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px; padding: 10px 0">
          <v-col cols="12" class="pt-0" style="padding-bottom: 20px; margin-bottom: 10px; border-bottom: 1px solid #ddd">
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field
                  v-model="baseFoodPlansSearchValue"
                  append-icon="mdi-magnify"
                  :label="showCalories ? str['search_by_calories'] : str['search']"
                  single-line
                  hide-details
                />
              </v-col>
              <v-col v-if="showFoodPlansCategory" :cols="showFoodPlansCategory2 ? 6 : 12">
                <v-autocomplete
                  v-model="baseFoodPlansFilters.category"
                  :label="foodPlansCategoryName ? str[foodPlansCategoryName] || foodPlansCategoryName : str['category']"
                  item-text="label"
                  item-value="value"
                  :items="nutritionCategories"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="showFoodPlansCategory2" cols="6">
                <v-autocomplete
                  v-model="baseFoodPlansFilters.category2"
                  :label="foodPlansCategory2Name ? str[foodPlansCategory2Name] || foodPlansCategory2Name : str['category']"
                  item-text="label"
                  item-value="value"
                  :items="nutritionCategories2"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
              <v-col v-if="showFoodPlansCaloriesFilter" cols="6" class="pt-0">
                <v-text-field
                  v-model="baseFoodPlansFilters.calories"
                  append-icon="mdi-magnify"
                  :label="str['calories']"
                  single-line
                  hide-details
                  clearable
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </v-col>
              <v-col v-if="showFoodPlansDaysCountFilter" cols="6" class="pt-0">
                <v-autocomplete
                  v-model="baseFoodPlansFilters.daysCount"
                  :label="str['number_of_meals']"
                  item-text="label"
                  item-value="value"
                  :items="nutritionNumberOfMeals"
                  :no-data-text="str['no_data']"
                  clearable
                  hide-details
                />
              </v-col>
            </v-row>
          </v-col>
          <div v-if="!baseFoodPlansFiltered.length" style="text-align: center; color: #888; padding: 40px 0">
            {{ str['no_data'] }}
          </div>
          <div
            v-for="baseFoodPlan in baseFoodPlansFiltered"
            :key="baseFoodPlan.id"
            class="box-select-item"
            @click="newFoodPlan(baseFoodPlan)"
          >
            {{ baseFoodPlan.name }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn color="default" @click="dialogNewFoodPlan = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" style="margin-right: 0" @click="newFoodPlan()">
            {{ str['create_new'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="showAutoAddBasePlan && autoFoodPlanLoading"
      v-model="autoFoodPlanLoading.visible"
      scrollable
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ autoFoodPlanLoading.finished ? str['finished'] : str['processing'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <div class="custom-scroll" style="height: 300px">
            <div
              v-for="(log, logIndex) in autoFoodPlanLoading.logs"
              :key="logIndex"
              :class="[log.type + '--text', log.result ? 'text-bold' : 'text-small']"
              class="mb-2"
            >
              • {{ log.text }}
            </div>
          </div>
        </v-card-text>
        <v-card-actions style="display: flex; justify-content: center">
          <v-btn v-if="autoFoodPlanLoading.finished" color="success" @click="autoFoodPlanLoading.visible = false">
            {{ str['ok'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showWaterToConsume" v-model="dialogWaterToConsume" max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['water_to_consume'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="waterToConsumeTemp" outlined hide-details class="input-centered" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="dialogWaterToConsume = false">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn color="success" @click="updateWaterToConsume()">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <client-formulas
      v-if="showFormulasListPage && hasPermission('nutrition_edit')"
      ref="formulas"
      :client="client"
      :mounted-callback="clientFormulasMounted"
    />
  </v-container>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'

export default {
  name: 'ClientFoodPlan',
  components: {
    ClientNotes: () => import('@/components/dashboard/client/Notes'),
    ClientPhotos: () => import('@/components/dashboard/client/Photos'),
    ClientFormulas: () => import('@/components/dashboard/client/Formulas'),
  },
  props: {
    client: Object,
  },
  data() {
    const user = Utils.getUser()

    let showCaloriesFormulas = false
    if (
      user &&
      user.configurations &&
      user.configurations.nutrition &&
      user.configurations.nutrition.show_calories &&
      user.configurations.nutrition.formulas
    ) {
      showCaloriesFormulas = true
    }

    let showCalories = false
    if (user && user.configurations && user.configurations.nutrition && user.configurations.nutrition.show_calories) {
      showCalories = true
    }

    let showFoodPlansCategory = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_category) {
      showFoodPlansCategory = true
    }

    let foodPlansCategoryName = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.category_name) {
      foodPlansCategoryName = user.configurations.food_plans.category_name
    }

    let showFoodPlansCategory2 = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_category2) {
      showFoodPlansCategory2 = true
    }

    let foodPlansCategory2Name = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.category2_name) {
      foodPlansCategory2Name = user.configurations.food_plans.category2_name
    }

    let showFoodPlansCaloriesFilter = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_calories_filter) {
      showFoodPlansCaloriesFilter = true
    }

    let showFoodPlansDaysCountFilter = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_days_count_filter) {
      showFoodPlansDaysCountFilter = true
    }

    let showUserNotes = false
    if (
      user &&
      user.configurations &&
      user.configurations.food_plans &&
      user.configurations.food_plans.show_user_notes &&
      Utils.hasPermission('user_notes_view')
    ) {
      showUserNotes = true
    }

    let showLastFeedbackPhotos = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.show_last_feedback_photos) {
      showLastFeedbackPhotos = true
    }

    let showAutoAddBasePlan = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_auto_add_base_plan) {
      showAutoAddBasePlan = true
    }

    let autoAdjustOptions = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.auto_adjust_options) {
      autoAdjustOptions = true
    }

    let autoAdjustFoods = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.auto_adjust_foods) {
      autoAdjustFoods = true
    }

    let autoSetName = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.auto_set_name) {
      autoSetName = user.configurations.food_plans.auto_set_name
    }

    let useAutoAddBasePlanFormulas = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.use_auto_add_base_plan_formulas) {
      useAutoAddBasePlanFormulas = true
    }

    let hasMacrosValidation = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.has_macros_validation) {
      hasMacrosValidation = user.configurations.food_plans.has_macros_validation
    }

    let showDateEnd = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_date_end) {
      showDateEnd = user.configurations.food_plans.show_date_end
    }

    let showWaterToConsume = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_water_to_consume) {
      showWaterToConsume = true
    }

    let showFormulasListPage = false
    if (user && user.configurations && user.configurations.food_plans && user.configurations.food_plans.show_formulas_list_page) {
      showFormulasListPage = true
    }

    let hasTranslations = false
    if (user && user.configurations && user.configurations.training_plans && user.configurations.training_plans.has_translations) {
      hasTranslations = user.configurations.training_plans.has_translations
    }

    const notes = showWaterToConsume ? this.getNotes() : null

    return {
      str: window.strings,
      hasPermission: Utils.hasPermission,
      user: user,
      isLoading: false,
      showCaloriesFormulas: showCaloriesFormulas,
      showCalories: showCalories,
      showFoodPlansCategory: showFoodPlansCategory,
      showFoodPlansCategory2: showFoodPlansCategory2,
      foodPlansCategoryName: foodPlansCategoryName,
      foodPlansCategory2Name: foodPlansCategory2Name,
      showFoodPlansCaloriesFilter: showFoodPlansCaloriesFilter,
      showFoodPlansDaysCountFilter: showFoodPlansDaysCountFilter,
      showUserNotes: showUserNotes,
      showLastFeedbackPhotos: showLastFeedbackPhotos,
      showAutoAddBasePlan: showAutoAddBasePlan,
      autoAdjustOptions: autoAdjustOptions,
      autoAdjustFoods: autoAdjustFoods,
      autoSetName: autoSetName,
      useAutoAddBasePlanFormulas: useAutoAddBasePlanFormulas,
      hasMacrosValidation: hasMacrosValidation,
      showDateEnd: showDateEnd,
      showWaterToConsume: showWaterToConsume,
      showFormulasListPage: showFormulasListPage,
      hasTranslations: hasTranslations,
      foodPlans: [],
      foodPlanStatusDict: {
        status: {},
        values: {},
      },
      baseFoodPlans: [],
      dialogNewFoodPlan: false,
      nutritionCategories: Utils.getNutritionCategories(),
      nutritionCategories2: Utils.getNutritionCategories2(),
      nutritionNumberOfMeals: Utils.getNutritionNumberOfMeals(),
      baseFoodPlansSearchValue: '',
      baseFoodPlansFilters: {
        category: null,
        category2: null,
        calories: null,
        daysCount: null,
      },
      caloriesFormulas: null,
      caloriesFormulasInputs: {},
      recipesItemsDict: null,
      recipesItemsNamesDict: null,
      clientGoals: Utils.getNutritionGoals(),
      clientGoal: null,
      autoFoodPlanLoading: {
        visible: false,
        logs: [],
        finished: false,
      },
      recipeCategoriesDict: this.getRecipeCategoriesDict(),
      foodData: {},
      waterToConsume: notes && notes.water_to_consume ? notes.water_to_consume : '',
      dialogWaterToConsume: false,
      waterToConsumeTemp: '',
    }
  },
  computed: {
    baseFoodPlansFiltered: function () {
      const self = this
      let baseFoodPlans = this.baseFoodPlans
      if (this.baseFoodPlansSearchValue) {
        const searchValue = this.baseFoodPlansSearchValue.toLowerCase()
        baseFoodPlans = baseFoodPlans.filter(function (item) {
          if (self.showCalories) {
            return item.calories.toString().toLowerCase().indexOf(searchValue) > -1 || item.name.toLowerCase().indexOf(searchValue) > -1
          } else {
            return item.name.toLowerCase().indexOf(searchValue) > -1
          }
        })
      }
      if (this.showFoodPlansCategory && this.baseFoodPlansFilters.category) {
        baseFoodPlans = baseFoodPlans.filter(function (item) {
          return item.category === self.baseFoodPlansFilters.category
        })
      }
      if (this.showFoodPlansCategory2 && this.baseFoodPlansFilters.category2) {
        baseFoodPlans = baseFoodPlans.filter(function (item) {
          return item.category2 === self.baseFoodPlansFilters.category2
        })
      }
      if (this.showFoodPlansCaloriesFilter && this.baseFoodPlansFilters.calories) {
        baseFoodPlans = baseFoodPlans.filter(function (item) {
          return item.calories && item.calories.toString().includes(self.baseFoodPlansFilters.calories)
        })
      }
      if (this.showFoodPlansDaysCountFilter && this.baseFoodPlansFilters.daysCount) {
        baseFoodPlans = baseFoodPlans.filter(function (item) {
          return item.days_count === parseInt(self.baseFoodPlansFilters.daysCount)
        })
      }
      return baseFoodPlans.sort((a, b) => a.name.localeCompare(b.name))
    },
    notesFoodPlan: {
      get() {
        return this.client.internalNotes.food_plan
      },
      set(value) {
        this.client.setInternalNotes('food_plan', value)
      },
    },
  },
  beforeMount: function () {
    const self = this
    if (!this.user) {
      return false
    }
    this.getFoodsItems()
    this.getFoodPlans()
    Utils.getPlanStatus().forEach(function (fp) {
      self.foodPlanStatusDict.values[fp.status] = fp.value
      self.foodPlanStatusDict.status[fp.value] = fp.status
    })
    this.getHeightAndWeight()
    this.setClientGoal()
  },
  methods: {
    getRecipeCategoriesDict: function () {
      const obj = {}
      const items = Utils.getRecipeCategories()
      if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
          obj[items[i].value] = window.strings[items[i].label] ? window.strings[items[i].label] : items[i].label
        }
      }
      return obj
    },
    getFoodsItems: function () {
      const self = this
      if (this.autoAdjustFoods) {
        Api.getFoods(
          {
            useCache: true,
          },
          function (response) {
            if (response.success) {
              self.foodData = {}
              for (let i = 0; i < response.data.length; i++) {
                self.foodData[response.data[i].id] = response.data[i]
              }
            }
          },
        )
      }
    },
    getFoodPlans: function () {
      const self = this
      this.isLoading = true
      Api.getFoodPlans(
        {
          client_id: this.client.dbId,
          fast: true,
        },
        function (response) {
          self.isLoading = false
          if (response.success) {
            self.foodPlans = response.data
          }
        },
      )
    },
    getHeightAndWeight: function () {
      const self = this
      Api.getLastPhysicalEvaluation(
        {
          id: this.client.dbId,
          useCache: true,
        },
        function (response) {
          if (response.success) {
            self.handleCaloriesFormulas(response.data[0])
          }
        },
      )
    },
    handleCaloriesFormulas: function (src) {
      const self = this
      if (this.showCaloriesFormulas) {
        try {
          let height = 0
          let weight = 0
          if (src) {
            height = src.body.height
            weight = src.body.weight
          }
          this.caloriesFormulas = {
            height: height,
            weight: weight,
            activityLevel: this.user.configurations.nutrition.formulas.activity_levels[0].value,
            activityLevelItems: this.user.configurations.nutrition.formulas.activity_levels,
            age: this.client.age,
            getValues: function () {
              const formulasValues = []
              try {
                if (self.caloriesFormulas) {
                  const formulas = self.user.configurations.nutrition.formulas[self.client.gender]
                  if (formulas) {
                    formulas.forEach(function (f) {
                      if (
                        parseInt(self.caloriesFormulas.height) &&
                        parseInt(self.caloriesFormulas.weight) &&
                        parseInt(self.caloriesFormulas.age)
                      ) {
                        let formula = f.formula.replace(/<HEIGHT>/g, self.caloriesFormulas.height)
                        formula = formula.replace(/<WEIGHT>/g, self.caloriesFormulas.weight)
                        formula = formula.replace(/<AGE>/g, self.caloriesFormulas.age)
                        formula = formula.replace(/<ACTIVITY_LEVEL>/g, self.caloriesFormulas.activityLevel)
                        formula = formula.replace(/,/g, '.')
                        formulasValues.push({
                          title: f.title,
                          value: new Function('return ' + formula)().toFixed(2),
                        })
                      } else {
                        formulasValues.push({
                          title: f.title,
                          value: window.strings['n_a'],
                        })
                      }
                    })
                  }
                }
              } catch {}
              return formulasValues
            },
          }
        } catch {}
      }
    },
    caloriesFormulasInputDisabled: function (item) {
      for (const key in this.caloriesFormulasInputs) {
        if (this.caloriesFormulasInputs[key] && key !== item) {
          return true
        }
      }
      return false
    },
    setClientGoal: function () {
      if (this.clientGoals && this.clientGoals.length) {
        const notes = this.getClientNotes()
        this.clientGoal = notes && notes.nutrition_goal ? notes.nutrition_goal : null
      }
    },
    addFoodPlan: function () {
      const self = this
      let baseFoodPlans = []
      this.$isLoading(true)
      Api.getFoodPlans(
        {
          fast: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            baseFoodPlans = response.data
          }
          if (baseFoodPlans.length) {
            self.baseFoodPlans = baseFoodPlans
            self.dialogNewFoodPlan = true
          } else {
            self.newFoodPlan()
          }
        },
      )
    },
    newFoodPlan: function (item) {
      const self = this

      if (item && item.id) {
        this.$isLoading(true)
        Api.getFoodPlan(
          {
            id: item.id,
          },
          function (response) {
            self.$isLoading(false)
            if (response.success) {
              confirm(response.data[0])
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      } else {
        confirm(item)
      }

      function confirm(baseFoodPlan) {
        self.dialogNewFoodPlan = false
        const defaultMacros = self.showFormulasListPage ? self.getUserMacros() : null
        const foodPlanData = {
          name: baseFoodPlan ? baseFoodPlan.name : 'Plano de nutrição',
          video_url: baseFoodPlan ? baseFoodPlan.video_url : null,
          description: baseFoodPlan ? baseFoodPlan.description : '',
          category: baseFoodPlan ? baseFoodPlan.category : '',
          category2: baseFoodPlan ? baseFoodPlan.category2 : '',
          proposal: baseFoodPlan ? baseFoodPlan.proposal : '',
          type: baseFoodPlan ? baseFoodPlan.type : '',
          days: baseFoodPlan ? baseFoodPlan.days : [],
          calories_goal: defaultMacros ? defaultMacros.calories_goal : baseFoodPlan ? baseFoodPlan.calories_goal : 0,
          protein_goal: defaultMacros ? defaultMacros.protein_goal : baseFoodPlan ? baseFoodPlan.protein_goal : 0,
          carbs_goal: defaultMacros ? defaultMacros.carbs_goal : baseFoodPlan ? baseFoodPlan.carbs_goal : 0,
          fat_goal: defaultMacros ? defaultMacros.fat_goal : baseFoodPlan ? baseFoodPlan.fat_goal : 0,
          fiber_goal: defaultMacros ? defaultMacros.fiber_goal : baseFoodPlan ? baseFoodPlan.fiber_goal : 0,
          sodium_goal: defaultMacros ? defaultMacros.sodium_goal : baseFoodPlan ? baseFoodPlan.sodium_goal : 0,
          sugar_goal: defaultMacros ? defaultMacros.sugar_goal : baseFoodPlan ? baseFoodPlan.sugar_goal : 0,
        }
        if (self.hasTranslations) {
          self.hasTranslations.forEach((t) => {
            foodPlanData['name_' + t] = baseFoodPlan['name_' + t]
            foodPlanData['description_' + t] = baseFoodPlan['description_' + t]
          })
        }
        Utils.setStorage('foodPlan', {
          data: foodPlanData,
          baseUsed: baseFoodPlan ? true : false,
          client: self.client,
        })
        self.$router.navigate.push({
          path: '/home/foodPlan',
          query: { new_plan: true },
        })
      }
    },
    getUserMacros: function () {
      try {
        let microcycle = this.client.microcycle
        if (microcycle) {
          microcycle = JSON.parse(microcycle)
          if (microcycle.food_plan_macros_goals) {
            return microcycle.food_plan_macros_goals
          }
        }
      } catch {}
      return null
    },
    openFoodPlan: function (item) {
      const self = this
      this.$isLoading(true)
      Api.getFoodPlan(
        {
          client_id: this.client.dbId,
          id: item.id,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            const src = response.data[0]
            self.dialogNewFoodPlan = false
            Utils.setStorage('foodPlan', {
              data: src,
              client: self.client,
            })
            self.$router.navigate.push({ path: '/home/foodPlan' })
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    changeFoodPlanStatus(item, status) {
      const self = this
      this.$isLoading(true)
      Api.getFoodPlan(
        {
          client_id: this.client.dbId,
          id: item.id,
        },
        function (response) {
          if (response.success) {
            const foodPlanToSave = JSON.parse(JSON.stringify(response.data[0]))
            foodPlanToSave.client_id = self.client.dbId
            foodPlanToSave.status = status
            Api.editFoodPlan(foodPlanToSave, function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.getFoodPlans()
              } else {
                self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
              }
            })
          } else {
            self.$isLoading(false)
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    addAutoBasePlan() {
      const self = this
      const notes = this.getClientNotes()
      const mealsCount = notes && notes.number_of_meals ? parseInt(notes.number_of_meals) : null
      let caloricDeficit = null
      if (!mealsCount) {
        return self.$alert(window.strings['auto_food_plan_base_no_meals_count'], '', 'warning', Utils.getAlertOptions())
      }
      this.getRecipes(function () {
        const includeRecipesIds = notes && notes.include_recipes ? notes.include_recipes : []
        const includeRecipes = []
        includeRecipesIds.forEach(function (id) {
          if (self.recipesItemsDict[id]) {
            includeRecipes.push(self.recipesItemsDict[id].name)
          }
        })
        const includeFoods = notes && notes.accepted_foods ? notes.accepted_foods : []
        let excludeFoods = []
        if (notes && notes.intolerant_foods) {
          excludeFoods = new Set([...excludeFoods, ...notes.intolerant_foods])
        }
        if (notes && notes.unaccepted_foods) {
          excludeFoods = new Set([...excludeFoods, ...notes.unaccepted_foods])
        }
        excludeFoods = Array.from(excludeFoods)

        if (self.caloriesFormulasInputs) {
          for (const key in self.caloriesFormulasInputs) {
            if (self.caloriesFormulasInputs[key]) {
              const formulasItems = self.caloriesFormulas.getValues()
              if (formulasItems) {
                const formulaFound = formulasItems.find(function (f) {
                  return f.title === key
                })
                if (formulaFound) {
                  const formulaValue = parseFloat(formulaFound.value)
                  const diffValue = self.caloriesFormulasInputs[key].replace(/^\D+/g, '')
                  caloricDeficit = self.caloriesFormulasInputs[key]
                  return newPlan(self.caloriesFormulasInputs[key].indexOf('-') > -1 ? formulaValue - diffValue : formulaValue + diffValue)
                }
              }
            }
          }
        }

        self
          .$prompt(self.str['calories'], '', '', '', Utils.getAlertOptions())
          .then((value) => {
            newPlan(parseFloat(value))
          })
          .catch(() => {})

        function newPlan(kcal) {
          self.autoFoodPlanLoading = {
            visible: true,
            logs: [],
            finished: false,
          }
          self.autoFoodPlanLoading.logs.push({
            text: 'Processo iniciado',
            type: 'secondary',
          })
          self.autoFoodPlanLoading.logs.push({
            text:
              'Adicionar plano de nutrição com as calorias ' +
              kcal +
              ' e tendo em conta as opções a incluir e alimentos a incluir e excluir',
            type: 'warning',
          })
          const payload = {
            client_id: self.client.dbId,
            kcal: parseInt(kcal),
            meals_count: mealsCount,
            exclude_foods: excludeFoods,
            include_foods: includeFoods,
          }
          const category = self.getAutoFoodPlanCategory()
          if (category || category === 0) {
            payload.category = category
          }
          if (includeRecipes && includeRecipes.length) {
            payload.include_recipes = includeRecipes
          }
          Api.newAutoFoodPlan(payload, function (response) {
            if (response.success) {
              self.autoFoodPlanLoading.logs.push({
                text: 'Plano de nutrição adicionado ' + response.data.name + '  |  ID: ' + response.data.id,
                type: 'success',
              })
              self.autoFoodPlanLoading.logs.push({
                text: response.message,
                type: 'success',
              })
              self.refreshAutoFoodPlanAdded(response.data, function () {
                finishLogs()
                self.getFoodPlans()
              })
            } else {
              self.autoFoodPlanLoading.logs.push({
                text: response.message,
                type: 'error',
              })
              finishLogs()
            }

            function finishLogs() {
              self.autoFoodPlanLoading.finished = true
              self.autoFoodPlanLoading.logs.push({
                text: 'Processo terminado',
                type: 'secondary',
              })
              self.updateNotes({
                caloric_intake: response.data.calories.toString(),
                caloric_deficit: caloricDeficit,
                add_auto_food_plan_logs: self.autoFoodPlanLoading.logs.map((log) => '-> ' + log.text).join('\n\n'),
              })
            }
          })
        }
      })
    },
    refreshAutoFoodPlanAdded: function (plan, callback) {
      const self = this
      let needsUpdate = false
      let caloriesDiff = null
      let proteinDiff = null
      let foodFields = null

      if (this.autoSetName) {
        needsUpdate = true
        plan.name = this.autoSetName
          .replace('<client_name>', this.client.name.toUpperCase())
          .replace('<weight>', this.caloriesFormulas.weight)
        this.autoFoodPlanLoading.logs.push({
          text: 'Nome do plano alterado para ' + plan.name,
          type: 'success',
        })
      }

      if (this.autoAdjustOptions) {
        processPlan(plan)
          .then(function () {
            if (self.autoAdjustFoods) {
              return processFoods(plan).then(finish).catch(finish)
            }
            finish()
          })
          .catch(finish)
      } else {
        finish()
      }

      async function processPlan(plan) {
        const notes = self.getClientNotes()
        const includeRecipesIds = JSON.parse(JSON.stringify(notes && notes.include_recipes ? notes.include_recipes : []))
        const includeRecipesAdded = []
        const includeRecipesCategories = {}
        caloriesDiff = self.hasMacrosValidation ? self.hasMacrosValidation.find((c) => c.id === 'calories') : null
        proteinDiff = self.hasMacrosValidation ? self.hasMacrosValidation.find((c) => c.id === 'protein') : null
        foodFields = ['food_id', 'type', 'value', 'calories', 'protein', 'carbs', 'fat']

        for (let d = 0; d < plan.days.length; d++) {
          for (let m = 0; m < plan.days[d].meals.length; m++) {
            const recipe = self.recipesItemsNamesDict[Utils.normalizeName(plan.days[d].meals[m].name)]
            if (recipe && includeRecipesIds.indexOf(recipe.id) > -1) {
              includeRecipesIds.splice(includeRecipesIds.indexOf(recipe.id), 1)
              includeRecipesAdded.push(recipe.id)
            }
          }
        }

        includeRecipesIds.forEach((id) => {
          const recipe = self.recipesItemsDict[id]
          if (recipe) {
            includeRecipesCategories[recipe.category] = includeRecipesCategories[recipe.category]
              ? includeRecipesCategories[recipe.category]
              : []
            includeRecipesCategories[recipe.category].push(recipe)
          }
        })

        for (let d = 0; d < plan.days.length; d++) {
          for (let m = 0; m < plan.days[d].meals.length; m++) {
            const recipe = self.recipesItemsNamesDict[Utils.normalizeName(plan.days[d].meals[m].name)]
            if (recipe && includeRecipesAdded.indexOf(recipe.id) === -1) {
              const mealCategory = plan.days[d].meals[m].category ? plan.days[d].meals[m].category : recipe.category
              const newMeal =
                includeRecipesCategories[mealCategory] && includeRecipesCategories[mealCategory][0]
                  ? includeRecipesCategories[mealCategory][0]
                  : null

              if (mealCategory && newMeal) {
                needsUpdate = true
                plan.days[d].meals[m].category = mealCategory
                const newMealUpdated = await processMeal(plan.days[d], plan.days[d].meals[m], newMeal)
                plan.days[d].meals[m].name = newMealUpdated.name
                plan.days[d].meals[m].description = newMealUpdated.description
                plan.days[d].meals[m].foods = newMealUpdated.foods
                plan.days[d].meals[m].image = newMealUpdated.image
                plan.days[d].meals[m].type = newMealUpdated.type
                plan.days[d].meals[m].category = newMealUpdated.category
                plan.days[d].meals[m].extra_data = newMealUpdated.extra_data
                includeRecipesCategories[mealCategory].splice(0, 1)
              }
            }
          }
        }
      }

      async function processFoods(plan) {
        self.autoFoodPlanLoading.logs.push({
          text: 'Verificar alimentos repetidos',
          type: 'warning',
        })
        for (let d = 0; d < plan.days.length; d++) {
          for (let m = 0; m < plan.days[d].meals.length; m++) {
            const mealFoodsAdded = []
            const mealChanged = JSON.parse(JSON.stringify(plan.days[d].meals[m]))
            let foodsRemoved = false
            for (let f = mealChanged.foods.length - 1; f > -1; f--) {
              if (mealFoodsAdded.indexOf(mealChanged.foods[f].food_id) > -1) {
                self.autoFoodPlanLoading.logs.push({
                  text: `${plan.days[d].name}: Alimento ${self.foodData[mealChanged.foods[f].food_id] ? self.foodData[mealChanged.foods[f].food_id].name : mealChanged.foods[f].food_id} eliminado na refeição ${plan.days[d].meals[m].name}`,
                  type: 'warning',
                })
                foodsRemoved = true
                mealChanged.foods.splice(f, 1)
              } else {
                mealFoodsAdded.push(mealChanged.foods[f].food_id)
              }
            }
            if (foodsRemoved) {
              const newMealUpdated = await processMeal(plan.days[d], plan.days[d].meals[m], mealChanged, true)
              plan.days[d].meals[m].foods = newMealUpdated.foods
            }
          }
        }
      }

      async function processMeal(day, currentMeal, newMeal, foodsRemoved) {
        return new Promise((resolve) => {
          self.autoFoodPlanLoading.logs.push({
            text: foodsRemoved
              ? `${day.name}: Ajustar refeição ${currentMeal.name}`
              : `${day.name}: Trocar ${currentMeal.name} (${self.recipeCategoriesDict[currentMeal.category]}) por ${newMeal.name} (${self.recipeCategoriesDict[newMeal.category]})`,
            type: 'warning',
          })

          const obj = {
            calories_min: currentMeal.calories,
            calories_max: currentMeal.calories,
            calories_diff: caloriesDiff ? caloriesDiff.value : 0,
            protein_min: currentMeal.protein,
            protein_max: currentMeal.protein,
            protein_diff: proteinDiff ? proteinDiff.value : 0,
            foods: [],
          }
          newMeal.foods.forEach((food) => {
            const foodObj = {}
            foodFields.forEach((key) => {
              foodObj[key] = food[key]
            })
            obj.foods.push(foodObj)
          })
          obj.foods.sort((a, b) => b.type - a.type)

          Api.autoMealAdjustment(obj, (response) => {
            if (response.data && response.data.foods) {
              newMeal.foods.forEach((food) => {
                const foodSrc = response.data.foods.find((s) => s.food_id === food.food_id)
                if (foodSrc) {
                  food.value = foodSrc.value
                }
              })
              self.autoFoodPlanLoading.logs.push({
                text: foodsRemoved
                  ? `${day.name}: Refeição ${currentMeal.name} ajustada`
                  : `${day.name}: Conseguiu trocar ${currentMeal.name} (${self.recipeCategoriesDict[currentMeal.category]}) por ${newMeal.name} (${self.recipeCategoriesDict[newMeal.category]})`,
                type: 'success',
              })
              resolve(newMeal)
            } else {
              self.autoFoodPlanLoading.logs.push({
                text: foodsRemoved
                  ? `${day.name}: Refeição ${currentMeal.name} não ajustada`
                  : `${day.name}: Não conseguiu trocar ${currentMeal.name} por ${newMeal.name}`,
                type: 'error',
              })
              resolve(currentMeal)
            }
          })
        })
      }

      function finish() {
        if (needsUpdate) {
          plan.base = 0
          plan.client_id = self.client.dbId
          Api.editFoodPlan(plan, (response) => {
            if (!response.success) {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
            callback()
          })
        } else {
          callback()
        }
      }
    },
    getRecipes: function (callback) {
      const self = this
      if (this.recipesItemsDict) {
        return callback()
      }
      this.$isLoading(true)
      Api.getRecipes(
        {
          useCache: true,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            const recipes = {}
            const recipesNames = {}
            response.data.forEach(function (item) {
              recipes[item.id] = item
              recipesNames[Utils.normalizeName(item.name)] = item
            })
            self.recipesItemsDict = recipes
            self.recipesItemsNamesDict = recipesNames
            callback()
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getAutoFoodPlanCategory: function () {
      try {
        const keys = ['food_intolerance', 'health_problems', 'food_vegetarian', 'include_supplements', 'pregnant_breastfeeding_or_neither']
        if (
          this.user &&
          this.user.configurations &&
          this.user.configurations.notes &&
          this.user.configurations.notes.nutrition &&
          this.user.configurations.notes.nutrition.fields_to_edit
        ) {
          const notes = this.getClientNotes()
          for (let i = 0; i < keys.length; i++) {
            if (notes && notes[keys[i]]) {
              const field = this.user.configurations.notes.nutrition.fields_to_edit.find(function (f) {
                return f.id === keys[i]
              })
              if (field && Utils[field.itemsEnums]) {
                const fieldItems = Utils[field.itemsEnums]()
                const item = fieldItems.find(function (it) {
                  return it.value === notes[keys[i]]
                })
                if (item && item.nutrition_category) {
                  return item.nutrition_category
                }
              }
            }
          }
          const defaultCategory = this.nutritionCategories.find(function (cat) {
            return cat.default
          })
          if (defaultCategory) {
            return defaultCategory.value
          }
        }
      } catch {}
      return false
    },
    getClientNotes: function () {
      let data = {}
      if (this.client) {
        try {
          const notes = JSON.parse(JSON.stringify(this.client.notes))
          data = JSON.parse(notes)
        } catch {}
      }
      return data
    },
    updateNotes: function (field, value) {
      if (this.$refs && this.$refs.clientNotes) {
        let notes = this.getClientNotes()
        if (!notes) {
          notes = {}
        }
        if (value) {
          notes[field] = value
        } else {
          for (const key in field) {
            if (field[key]) {
              notes[key] = field[key]
            }
          }
        }
        this.$refs.clientNotes.updateNotes(notes, null, !value ? true : false)
      }
    },
    openWaterToConsume: function () {
      this.waterToConsumeTemp = this.waterToConsume
      this.dialogWaterToConsume = true
    },
    updateWaterToConsume: function () {
      const self = this
      const notes = this.getNotes()
      notes.water_to_consume = this.waterToConsumeTemp
      this.$isLoading(true)
      Api.updateUser(
        {
          id: this.client.id,
          notes: JSON.stringify(notes),
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.dialogWaterToConsume = false
            self.client.setValue('notes', response.data.notes)
            Utils.setStorage('client', self.client)
            self.waterToConsume = notes.water_to_consume
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getNotes: function () {
      let data = {}
      try {
        if (this.client.notes) {
          data = JSON.parse(this.client.notes)
        }
      } catch {}
      return data
    },
    clientFormulasMounted() {
      this.formulasRef = this.$refs.formulas
    },
    openFormulasDialog: function () {
      this.formulasRef.open()
    },
    getMacrosGoals: function () {
      try {
        let microcycle = this.client.microcycle
        if (microcycle) {
          microcycle = JSON.parse(microcycle)
          if (microcycle.food_plan_macros_goals) {
            const values = []
            for (const key in microcycle.food_plan_macros_goals) {
              values.push({
                label: window.strings[key.replace('_goal', '')],
                value: microcycle.food_plan_macros_goals[key],
              })
            }
            return values
          }
        }
      } catch (error) {
        return null
      }
      return null
    },
  },
}
</script>
